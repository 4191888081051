<template>
  <div>
    <AppSmartList
      ref="appSmartList"
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
      @app-smart-list-action-new-record="newRecordAction"
      @app-smart-list-open-modal-details="openModalDetailsAction"
    ></AppSmartList>

    <!--begin:: App Update Details Modal-->
    <ProjectTypeManagementModal
      v-model="projectRoleDetailsModal.isShow"
      :id="projectRoleDetailsModal.id"
      :isNewRecord="projectRoleDetailsModal.isNewRecord"
    ></ProjectTypeManagementModal>
    <!--end:: App Update Details Modal-->
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList';
  import ProjectTypeManagementModal from '@/views/components/talent-category/project-type/ProjectTypeManagementModal';
  import { listService } from '@/core/services';
  import {
    LOCAL,
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    PROJECT_TYPES_GET_PROJECT_TYPES,
    PROJECT_TYPE_DELETE_PROJECT_TYPE,
    PROJECT_TYPES_INITIAL_PROJECT_TYPES_STATE,
    PROJECT_TYPE_INITIAL_DELETE_PROJECT_TYPE_STATE
  } from '@/core/store/project-types.module';

  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  export default {
    name: 'ProjectTypeList',
    components: {
      AppSmartList,
      ProjectTypeManagementModal
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.talentCategory'),
            route: { name: ROUTE_NAME.TALENT_CATEGORY }
          },
          {
            title: i18nHelper.getMessage('label.projectType'),
            route: { name: ROUTE_NAME.TALENT_CATEGORY_PROJECT_TYPE }
          },
          { title: i18nHelper.getMessage('label.projectTypeList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.PROJECT_ROLE,
        actions: {
          create: ACTION.PROJECT_ROLE_CREATE,
          read: ACTION.PROJECT_ROLE_READ,
          update: ACTION.PROJECT_ROLE_UPDATE,
          delete: ACTION.PROJECT_ROLE_DELETE
        }
      },
      filters: [
        {
          label: 'name',
          key: 'name',
          value: ''
        },
        {
          label: 'dataset',
          key: 'datasetId',
          value: null,
          options: listService.getDynamicList(
            CONSTANTS_DATA.projectTypeDataSet
          ),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'projectTypes',
          state: 'projectTypes',
          action: PROJECT_TYPES_GET_PROJECT_TYPES,
          initialStateAction: PROJECT_TYPES_INITIAL_PROJECT_TYPES_STATE,
          title: i18nHelper.getMessage('label.getProjectTypes')
        },
        deleteRecord: {
          module: 'projectTypes',
          state: 'deleteProjectType',
          action: PROJECT_TYPE_DELETE_PROJECT_TYPE,
          initialAction: PROJECT_TYPE_INITIAL_DELETE_PROJECT_TYPE_STATE,
          title: i18nHelper.getMessage('label.deleteProjectType'),
          message: i18nHelper.getMessage('message.requestDeleteMessage', [
            i18nHelper.getMessage('label.projectType').toLowerCase()
          ]),
          successAction: () => {}
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          active: true,
          sortable: true
        },
        {
          key: 'datasetId',
          name: 'dataset',
          active: true,
          sortable: true
        },
        {
          key: 'isDeleted',
          name: 'isDeleted',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            isModal: true,
            hasActionDelete: true
          }
        }
      ],
      projectRoleDetailsModal: {
        isShow: false,
        id: 0,
        isNewRecord: false
      },
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.newProjectType'),
        isModal: true
      }
    }),
    created() {
      this.initProjectRoleList();
    },
    methods: {
      importSuccessAction() {
        this.actions.deleteRecord.successAction = () => {
          this.$refs.appSmartList.initAppSmartList();
        };
      },
      newRecordAction() {
        this.openProjectTypeManagementModal(true);
      },
      openModalDetailsAction(value) {
        this.openProjectTypeManagementModal(false, value);
      },
      openProjectTypeManagementModal(isNewRecord, id) {
        this.projectRoleDetailsModal = {
          isShow: true,
          id: id,
          isNewRecord: isNewRecord
        };
      },
      initProjectRoleList() {
        this.importSuccessAction();
      }
    }
  };
</script>

<style></style>

<template>
  <b-modal
    v-model="modal"
    :title="title"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    scrollable
    header-class="text-capitalize"
    body-class="max-h-325px project-type-management-modal"
  >
    <!-- begin:: Modal Body -->
    <!-- begin:: Form input -->
    <b-form-group class="mb-0">
      <template v-for="(item, index) in formFields">
        <AppFormField
          :key="index"
          v-if="isShowFormFields(item)"
          v-model="$v.projectTypeObj[item.model].$model"
          :state="validateObjState('projectTypeObj', item.model)"
          :validationValue="$v.projectTypeObj[item.model]"
          :title="item.title"
          :validators="item.validators"
          :formType="item.formType"
          :options="item.options"
          :disabled="isFormDisabled(item)"
          solid
          class="mb-6"
        ></AppFormField>
      </template>
    </b-form-group>
    <!-- end:: Form Input -->

    <!-- begin:: Display Field Wrapper-->
    <div
      v-if="!isNewRecord"
      class="project-type-management-modal__display-field-wrapper"
    >
      <template v-for="(item, index) in displayFields">
        <div
          :key="index"
          class="project-type-management-modal__display-field-container mb-6"
        >
          <label class="font-weight-bold font-size-md mb-2">
            {{ item.label }}:
          </label>
          <component
            v-if="item.component"
            :is="item.component"
            v-model="projectTypeObj[item.property]"
          ></component>
          <div v-else>{{ projectTypeObj[item.property] }}</div>
        </div>
      </template>
    </div>
    <!-- end:: Display Field -->

    <!-- end:: Modal Body -->

    <!-- begin:: Modal Footer -->
    <template #modal-footer>
      <b-button
        v-if="isShowDiscardButton"
        class="mr-2"
        variant="white"
        @click="onClickDiscard"
        >{{ $t('label.discard') }}</b-button
      >
      <b-button
        v-if="isShowSaveChangesButton"
        variant="primary"
        @click="onClickSaveChanges"
        >{{ $t('label.saveChanges') }}</b-button
      >
      <b-button
        v-if="isShowSubmitButton"
        variant="success"
        @click="onClickSubmit"
        >{{ $t('label.submit') }}</b-button
      >
      <b-button
        v-if="isShowCloseButton"
        variant="primary"
        @click="onClickClose"
        >{{ $t('label.close') }}</b-button
      >
    </template>
    <!-- end:: Modal Footer -->
  </b-modal>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import AppCheckCircle from '@/views/components/bases/AppCheckCircle';
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION, LOCAL } from '@/core/constants';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import { listService } from '@/core/services';
  import {
    PROJECT_TYPE_GET_PROJECT_TYPE,
    PROJECT_TYPE_CREATE_PROJECT_TYPE,
    PROJECT_TYPE_UPDATE_PROJECT_TYPE,
    PROJECT_TYPE_INITIAL_PROJECT_TYPE_STATE,
    PROJECT_TYPE_INITIAL_CREATE_PROJECT_TYPE_STATE,
    PROJECT_TYPE_INITIAL_UPDATE_PROJECT_TYPE_STATE
  } from '@/core/store/project-types.module';

  const CONSTANTS_DATA = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));

  export default {
    name: 'ProjectTypeManagementModal',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField,
      AppCheckCircle
    },
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true
      },
      id: {
        type: Number,
        default: 0,
        required: true
      },
      isNewRecord: {
        type: Boolean,
        default: false,
        required: true
      }
    },
    data: () => ({
      modal: false,
      currentModule: MODULE.PROJECT_TYPE,
      projectTypeObj: {
        name: null,
        datasetId: null,
        isDeleted: false
      },
      formFields: [
        {
          model: 'name',
          title: i18nHelper.getMessage('label.name'),
          validators: ['required']
        },
        {
          model: 'datasetId',
          title: i18nHelper.getMessage('label.dataset'),
          validators: ['required'],
          formType: 'select',
          options: listService.getDynamicList(CONSTANTS_DATA.projectTypeDataSet)
        }
      ],
      displayFields: [
        // {
        //   property: 'datasetId',
        //   label: i18nHelper.getMessage('label.dataset')
        // },
        {
          property: 'isDeleted',
          label: i18nHelper.getMessage('label.isDeleted'),
          component: 'AppCheckCircle'
        }
      ]
    }),
    computed: {
      projectTypeComplete() {
        return this.$store.state.projectTypes.projectType.complete;
      },
      createProjectTypeComplete() {
        return this.$store.state.projectTypes.createProjectType.complete;
      },
      updateProjectTypeComplete() {
        return this.$store.state.projectTypes.updateProjectType.complete;
      },
      isShowSubmitButton() {
        return (
          this.isNewRecord &&
          this.checkAccessRight(this.currentModule, [
            ACTION.PROJECT_TYPE_CREATE
          ])
        );
      },
      isShowDiscardButton() {
        return this.isNewRecord
          ? true
          : this.checkAccessRight(this.currentModule, [
              ACTION.PROJECT_TYPE_UPDATE
            ]);
      },
      isShowCloseButton() {
        return this.isNewRecord
          ? false
          : !this.checkAccessRight(this.currentModule, [
              ACTION.PROJECT_TYPE_UPDATE
            ]);
      },
      title() {
        return this.isNewRecord
          ? i18nHelper.getMessage('label.newProjectType')
          : i18nHelper.getMessage('label.projectTypeDetails');
      },
      isShowSaveChangesButton() {
        return (
          !this.isNewRecord &&
          this.checkAccessRight(this.currentModule, [
            ACTION.PROJECT_TYPE_UPDATE
          ])
        );
      }
    },
    watch: {
      value() {
        if (this.value) {
          if (this.isNewRecord) {
            this.openModalAction();
          } else {
            this.getProjectType(this.id);
          }
        }
      },
      modal() {
        this.modal
          ? uiHelper.freezeBodyOverflowY()
          : uiHelper.unFreezeBodyOverflowY();
      },
      projectTypeComplete() {
        let response = this.$store.state.projectTypes.projectType;
        let title = i18nHelper.getMessage('label.getProjectType');
        let initialStateAction = PROJECT_TYPE_INITIAL_PROJECT_TYPE_STATE;
        let successAction = (response) => {
          this.getProjectTypeCompleteActions(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      createProjectTypeComplete() {
        var response = this.$store.state.projectTypes.createProjectType;
        var title = i18nHelper.getMessage('label.createNewProjectType');
        var initialStateAction = PROJECT_TYPE_INITIAL_CREATE_PROJECT_TYPE_STATE;
        var successAction = () => {
          this.closeModalAction();
          this.initialList();
        };

        if (response.complete) {
          this.actionCreateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      updateProjectTypeComplete() {
        var response = this.$store.state.projectTypes.updateProjectType;
        var title = i18nHelper.getMessage('label.updateProjectType');
        var initialStateAction = PROJECT_TYPE_INITIAL_UPDATE_PROJECT_TYPE_STATE;
        var successAction = () => {
          this.closeModalAction();
          this.initialList();
        };

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    methods: {
      isShowFormFields() {
        var isShow = true;

        // if (!this.isNewRecord) {
        //   if (item.model == 'datasetId') {
        //     isShow = false;
        //   }
        // }
        return isShow;
      },
      openModalAction() {
        this.resetForm();
        this.modal = true;
      },
      isFormDisabled() {
        var moduleName = this.currentModule;
        var hasAccessRight = (module, actions) => {
          return this.checkAccessRight(module, actions);
        };

        // if (item.model == 'datasetId') {
        //   return !this.isNewRecord;
        // } else {
          return this.isNewRecord
            ? !hasAccessRight(moduleName, [ACTION.PROJECT_TYPE_CREATE])
            : !hasAccessRight(moduleName, [ACTION.PROJECT_TYPE_UPDATE]);
        // }
      },
      onClickSaveChanges() {
        var data = this.projectTypeObj;
        var id = this.id;

        if (this.validationCheck('projectTypeObj')) {
          this.updateProjectType(id, data);
        }
      },
      onClickDiscard() {
        this.closeModalAction();
      },
      onClickSubmit() {
        var data = this.projectTypeObj;

        if (this.validationCheck('projectTypeObj')) {
          this.createProjectType(data);
        }
      },
      initialList() {
        this.$parent.$refs.appSmartList.initAppSmartList();
      },
      getProjectTypeCompleteActions(response) {
        let constantProjectTypeDataset = CONSTANTS_DATA.projectTypeDataSet;
        this.projectTypeObj = {
          ...response.data,
          datasetId: constantProjectTypeDataset.find((x) => x.description == response.data.datasetId).value
        };
        this.modal = true;

        this.$v.$reset();
      },
      onClickClose() {
        this.closeModalAction();
      },
      resetForm() {
        this.projectTypeObj = {
          name: null,
          datasetId: null,
          isDeleted: false
        };

        this.$v.$reset();
      },
      closeModalAction() {
        this.resetForm();
        this.input(false);
        this.modal = false;
      },
      input(value) {
        this.$emit('input', value);
      },
      updateProjectType(id, data) {
        this.$store.dispatch(PROJECT_TYPE_UPDATE_PROJECT_TYPE, { id, data });
      },
      createProjectType(data) {
        this.$store.dispatch(PROJECT_TYPE_CREATE_PROJECT_TYPE, { data });
      },
      getProjectType(id) {
        this.$store.dispatch(PROJECT_TYPE_GET_PROJECT_TYPE, {
          id
        });
      }
    },
    validations: {
      projectTypeObj: {
        name: {
          required
        },
        datasetId: {
          required
        }
      }
    }
  };
</script>

<style lang="scss"></style>
